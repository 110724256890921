import { useEffect, useState } from "react";
import Getusers from "api/users";
import { useAuth } from "auth-context/auth.context";

export function useUsersData() {
  const { user } = useAuth();
  const [usersData, setUsersData] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [usersTotalPoints, setUsersTotalPoints] = useState("");


  const updatePointsUser = async (userId, data) => {
    try {
      await Getusers.updatePointsUser(user.token, userId, data);
    } catch (error) {
      console.error("Error al actualizar los puntos del usuario:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.token) {
        try {
          const response = await Getusers.getUsers(user.token);
    
          setUsersData(response.data);
      
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };
    fetchData();
  }, [user]);

  

  useEffect(() => {
    const fetchTotalUsersPointsData = async () => {
      if (user && user.token) {
        try {
          const response = await Getusers.getTotalUsersPoints(user.token);
          setUsersTotalPoints(response.data.total_points);
        } catch (error) {}
      }
    };
    fetchTotalUsersPointsData();
  }, [user, usersData, usersTotalPoints]);

  const createUser = async (formData) => {
    try {
      await Getusers.registerUser(formData);
      const response = await Getusers.getUsers(user.token);
        setUsersData(response.data);
      
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        if (
          serverErrors.email &&
          serverErrors.email.length > 0 &&
          serverErrors.num_document &&
          serverErrors.num_document.length > 0
        ) {
          console.error(
            "Ya existe un usuario con ese número de documento y email."
          );
          throw new Error(
            "Ya existe un usuario con ese número de documento y email."
          );
        }
        if (serverErrors.email && serverErrors.email.length > 0) {
          console.error(serverErrors.email[0]);
          throw new Error(serverErrors.email[0]);
        }
        if (serverErrors.num_document && serverErrors.num_document.length > 0) {
          console.error(serverErrors.num_document[0]);
          throw new Error(serverErrors.num_document[0]);
        }
      } else {
        console.error("Error al crear usuario:", error);
        throw new Error("Hubo un error al crear el usuario.");
      }
    }
  };

  useEffect(() => {

    if (usersData) {
      setTotalUsersCount(usersData.length);
    }
  }, [usersData]);
  

  const changePassword = async (newPassword) => {
    try {
      await Getusers.changePassword(user.token, newPassword);
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      throw new Error(
        error.message || "Hubo un error al cambiar de contraseña."
      );
    }
  };
  const getUsersData = async () => {
    try {
      const response = await Getusers.getUsers(user.token);
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      return []; // Or handle error appropriately
    }
  };


  return {
    usersData,
    totalUsersCount,
    usersTotalPoints,
    updatePointsUser,
    createUser,
    changePassword,
    getUsersData
  };
}
